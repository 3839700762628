'use client'

import { ButtonIconTransparent } from 'fukku/Button/Icon/Transparent'
import { IconCloseSmall } from 'icons/components/IconCloseSmall'
import { IconInfoL } from 'icons/components/IconInfoL'
import { useEffect, useRef, useState } from 'react'
import { applyStylesIf, cx } from 'utils/cx'

import { BUTTON_VARIANT } from '../../../Button/Button.types'
import type { ClientTextLinkProps } from '../../../TextLink/client'
import { Tooltip } from '../../../Tooltip'
import { COMM_BANNER_BG_COLOR_TYPE, type CommElement } from '../../types'
import { isValidCustomColor, isValidPromoColor } from '../../utils'
import { CommBannerLinkWrapper } from '../CommBannerLinkWrapper/CommBannerLinkWrapper'

import styles from './ClosableWrapper.module.scss'

interface ClosableWrapperProps {
	id: string
	onShow?: (props: CommElement) => void
	isClosable: boolean
	closeButtonLabel?: string
	className?: string
	children: React.ReactNode
	isHiddenInCarousel?: boolean
	bgColorType?: COMM_BANNER_BG_COLOR_TYPE
	bgColorCode?: string
	isInverse?: boolean
	showInfoTooltip?: boolean
	infoText?: string
	infoIconLabel?: string
	infoIconButtonCarouselClassName?: string
	firstLink?: ClientTextLinkProps
}

interface ColorClassNamesProps {
	bgColorType?: COMM_BANNER_BG_COLOR_TYPE
	bgColorCode?: string
	isInverse?: boolean
}

const getColorClassNames = ({
	bgColorType,
	bgColorCode,
	isInverse = false,
}: ColorClassNamesProps) => {
	if (bgColorType && isValidPromoColor(bgColorType)) {
		return cx(styles[bgColorType], applyStylesIf(isInverse, styles.inverse))
	}
	if (isValidCustomColor(bgColorType, bgColorCode)) {
		return applyStylesIf(isInverse, styles.inverse)
	}
	return cx(styles.white, applyStylesIf(isInverse, styles.inverse))
}

export function ClosableWrapper({
	id,
	onShow,
	isClosable,
	closeButtonLabel,
	className,
	children,
	isHiddenInCarousel = false,
	isInverse = false,
	bgColorType,
	bgColorCode,
	showInfoTooltip,
	infoText,
	infoIconLabel,
	infoIconButtonCarouselClassName,
	firstLink,
}: ClosableWrapperProps) {
	const commRef = useRef(null)
	const colorStyle = isValidCustomColor(bgColorType, bgColorCode)
		? { backgroundColor: bgColorCode }
		: {}
	const colorClassNames = getColorClassNames({
		bgColorType,
		bgColorCode,
		isInverse,
	})

	const [isVisible, setIsVisible] = useState<boolean>(true)

	useEffect(() => {
		if (!isHiddenInCarousel && onShow) {
			onShow({ id, commRef })
		}
	}, [isHiddenInCarousel, onShow])

	const handleCloseBanner = () => {
		setIsVisible(false)
	}

	const tabIndex = isHiddenInCarousel ? -1 : undefined

	if (isVisible) {
		return (
			<CommBannerLinkWrapper
				tabIndex={tabIndex}
				commRef={commRef}
				colorStyle={colorStyle}
				wrapperClassName={cx(
					styles.closableWrapper,
					colorClassNames,
					className
				)}
				firstLink={firstLink}
			>
				{children}
				{isClosable && (
					<ButtonIconTransparent
						onClick={handleCloseBanner}
						className={styles.closeButton}
						title={closeButtonLabel ?? ''}
						variant={
							isInverse ? BUTTON_VARIANT.INVERSE : BUTTON_VARIANT.DEFAULT
						}
					>
						<IconCloseSmall width={26} height={26} />
					</ButtonIconTransparent>
				)}
				{showInfoTooltip && (
					<Tooltip
						position='bottom'
						content={<p>{infoText}</p>}
						id={`commsBannerTooltip${id}`}
						boundary={commRef}
					>
						<button
							title={infoIconLabel}
							className={cx(
								styles.infoIconButton,
								infoIconButtonCarouselClassName ??
									styles.infoIconButtonSingleComm
							)}
							onClick={(e) => {
								e.stopPropagation()
								e.preventDefault()
							}}
							tabIndex={tabIndex}
							aria-label='info-icon'
						>
							<IconInfoL width={12} height={12} />
						</button>
					</Tooltip>
				)}
			</CommBannerLinkWrapper>
		)
	}

	return <></>
}
